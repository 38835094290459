import React, { useState } from "react";
import ProForMobile from "./ProForMobile";

const MobileProps = ({ ps }) => {
  const [index, setIndex] = useState(0);
  const clickLeft = () => {
    if (ps?.length) {
      if (index == 0) {
        setIndex(ps.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const clickRight = () => {
    if (ps?.length) {
      if (index == ps.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
  };
  const renderImg = (type) => {
    if (type === "business") {
      return <img src="/business.svg" />;
    } else if (type === "civil") {
      return <img src="/civil.svg" />;
    } else if (type === "family") {
      return <img src="/family.svg" />;
    } else if (type === "labor") {
      return <img src="/labor.svg" />;
    } else if (type === "intellectual") {
      return <img src={"/intellectual.svg"} />;
    } else if (type === "criminal") {
      return <img src={"/chin.svg"} />;
    } else if (type === "public") {
      return <img src={"/public.svg"} />;
    }
  };
  const hellops = (show, setShow) => {
    const current = ps[index];
    if (!current) return <div>目前沒有資料</div>;
    const { title, contents, icon } = current;
    return (
      <div className="mb-[39px]">
        <ProForMobile title={title} props={contents}>
          {renderImg(icon)}
        </ProForMobile>
      </div>
    );
  };

  return (
    <div className="relative mt-[60px] h-[450px]">
      <div
        onClick={clickLeft}
        className="absolute left-0 top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        "
      >
        <img src="/larrow.svg" />
      </div>
      <div
        onClick={clickRight}
        className="absolute right-0 top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        "
      >
        <img src="/rarrow.svg" />
      </div>
      {hellops()}
    </div>
  );
};
export default MobileProps;
