import React, { useState } from "react";

const MobilePartner = ({ partners }) => {
  const [index, setIndex] = useState(0);

  if (partners) {
    partners = partners.map((partner, i) => {
      return {
        idx: i,
        ...partner,
      };
    });
  }

  const clickLeft = () => {
    if (partners?.length) {
      if (index == 0) {
        setIndex(partners.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const clickRight = () => {
    if (partners?.length) {
      if (index == partners.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const renderPartner = () => {
    const current = partners[index];
    if (!current) return <div>目前沒有資料</div>;
    const { education, licence, name } = current.data;
    return (
      <div
        className="border-[1px] border-[#001850]
      rounded-[4px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]
      w-[327px] h-[337px] py-[20px] px-[24px] text-[#828282]
      bg-white relative overflow-y-scroll
      overflow-x-scroll

      "
      >
        <div className="text-[#001850] text-[20px] font-bold mb-[4px]">
          {name[0].text}
        </div>
        <div className="bg-[#c7a572] h-[2px] w-[61px] mb-[16px]" />
        <div className="mb-[16px]">
          <div className="font-bold mb-[4px]">學歷</div>
          {Object.values(education).length ? (
            <ul className="list-disc pl-[20px] ">
              {education.map((edu, i) => (
                <li key={`${i}_${edu.text}`}>{edu.text}</li>
              ))}
            </ul>
          ) : null}
        </div>
        {licence.length ? (
          <div className="mb-[16px]">
            <div className="mb-[4px]">
              <div className="font-bold">專業證照</div>
            </div>
            <ul className="list-disc list-inside">
              {licence.map((li, i) => (
                <li key={`mobile_${i}_${li.text}`}>{li.text}</li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="absolute bottom-[20px] right-[20px]">
          <img src="/partner_logo.svg" width={100} height={117} />
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <div
        onClick={clickLeft}
        className="absolute left-0 top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        opacity-[0.45]
        "
      >
        <img src="/larrow.svg" />
      </div>
      <div
        onClick={clickRight}
        className="absolute right-0 top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        opacity-[0.45]
        "
      >
        <img src="/rarrow.svg" />
      </div>
      {renderPartner()}
    </div>
  );
};

export default MobilePartner;
