import React, { useState } from "react";

const Partner = ({ partners }) => {
  // 第0組 每組3個
  const [index, setIndex] = useState(0);

  let newPartnerGroup = [];
  // console.log("partners'", partners.length);

  if (partners) {
    for (let i = 0; i < partners.length; i += 3) {
      newPartnerGroup.push(partners.slice(i, i + 3));
    }
  }
  // console.log("newPartnerGroup", newPartnerGroup);

  const clickLeft = () => {
    if (newPartnerGroup?.length) {
      if (index == 0) {
        setIndex(newPartnerGroup.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const clickRight = () => {
    if (newPartnerGroup?.length) {
      if (index == newPartnerGroup.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
  };
  const renderPartner = () => {
    const currentGroup = newPartnerGroup[index];
    if (!currentGroup?.length) return <div>目前沒有資料</div>;
    return currentGroup.map((current, i) => {
      const { education, licence, name } = current.data;
      return (
        <div
          key={current.id + "abc"}
          className="border-[1px] border-[#001850]
      rounded-[4px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]
      w-[327px] h-[337px] py-[20px] px-[24px] text-[#828282]
      bg-white relative overflow-y-scroll
      overflow-x-scroll  min-w-[327px] mr-[20px]
      "
        >
          <div className="text-[#001850] text-[20px] font-bold mb-[4px]">
            {name[0]?.text}
          </div>
          <div className="bg-[#c7a572] h-[2px] w-[61px] mb-[16px]" />
          <div className="mb-[16px]">
            <div className="font-bold mb-[4px]">學歷</div>
            {Object.values(education).length ? (
              <ul className="list-disc pl-[20px] ">
                {education.map((edu, i) => (
                  <li key={`${i}_${edu.text}`}>{edu.text}</li>
                ))}
              </ul>
            ) : null}
          </div>
          {licence?.length ? (
            <div className="mb-[16px]">
              <div className="mb-[4px]">
                <div className="font-bold">專業證照</div>
              </div>
              <ul className="list-disc list-inside">
                {licence?.map((li) => (
                  <li key={`big_${i}_${li.text}`}>{li.text}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className="absolute bottom-[20px] right-[20px]">
            <img src="/partner_logo.svg" width={100} height={117} />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex w-full flex justify-center relative">
      {partners?.length > 3 ? (
        <div
          onClick={clickLeft}
          className="absolute left-[-10px] top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        opacity-[0.45]
        "
        >
          <img src="/larrow.svg" />
        </div>
      ) : null}
      {partners?.length > 3 ? (
        <div
          onClick={clickRight}
          className="absolute right-[-10px] top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        opacity-[0.45]
        "
        >
          <img src="/rarrow.svg" />
        </div>
      ) : null}
      <div className="flex justify-around w-full">{renderPartner()}</div>
    </div>
  );
};

export default Partner;
