import * as prismic from "@prismicio/client";
export const repositoryName = "jk-law0";

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImprLWxhdzAtYjMxNDg0YTAtMzU4Ny00YjIyLWI1ZDktMzYyNjBlZTNhOTVkXzQiLCJkYXRlIjoxNjg5OTQ5NDQzLCJkb21haW4iOiJqay1sYXcwIiwiaWF0IjoxNjg5OTQ5NDQzfQ.5skpCLgcKQ2a8SMyjL2d-1rjHkD6ifNaoHFEPDj_USo",

  // This defines how you will structure URL paths in your project.
  // Update the types to match the custom types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    {
      type: "team_member",
      path: "/",
    },
    {
      type: "partner",
      path: "/",
    },
  ],
});
