const Team = () => {
  return (
    <div className="flex text-[20px] md:text-[32px] text-[#001F66] font-bold flex items-center">
      <div className="mr-[24px] md:mr-[44px]">
        <img src="/threeline.svg" className="h-[40px] md:h-[64px]" />
      </div>
      <div className="mr-[24px] md:mr-[44px]">我們的團隊</div>
      <div>
        <img src="/threeline.svg" className="h-[40px] md:h-[64px]" />
      </div>
    </div>
  );
};

export default Team;
