import React, { memo, useEffect, useRef, useState } from "react";
import ClickOutside from "./ClickOutSide";

const Member = ({ members }) => {
  const [show, setShow] = useState({ 0: false, 1: false });

  useEffect(() => {
    if (show["0"] || show["1"]) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show["0"], show["1"]]);

  return (
    <div className="w-full flex justify-center">
      {members.map((member, i) => {
        if (!member) return;
        const {
          education,
          experiences,
          image,
          license,
          name,
          professional,
          works,
        } = member.data;
        return (
          <div
            key={`${name[0]?.text}_${i}`}
            className="flex flex-wrap justify-center w-[650px] mr-[20px] relative mb-[96px]"
          >
            {/*照片*/}
            <div className="w-[280px] h-[375px]">
              <img
                src={image.url}
                alt=""
                width={280}
                height={375}
                className="rounded-[4px]"
              />
            </div>
            {/*敘述*/}
            <div className="w-[300px] ml-[20px] text-[16px] text-[#828282] h-[525px] overflow-hidden ">
              <div className="text-[#001850] text-[20px] font-bold mb-[4px]">
                {name[0].text}
              </div>
              <div className="bg-[#c7a572] h-[2px] w-[61px] mb-[16px]" />
              <div className="mb-[16px]">
                <div className="font-bold mb-[4px]">學歷</div>
                {Object.values(education).length ? (
                  <ul className="list-disc pl-[20px] ">
                    {education.map((edu, i) => (
                      <li key={`${i}_${edu.text}`}>{edu.text}</li>
                    ))}
                  </ul>
                ) : null}
              </div>
              {Object.values(experiences).length ? (
                <div className="mb-[16px]">
                  <div className="font-bold mb-[4px]">經歷</div>
                  <ul className="list-disc list-outside pl-[20px]">
                    {experiences.map((exp, i) => (
                      <li key={`big_${i}_${exp.text}`} className="">
                        {exp.text}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {Object.keys(professional).length ? (
                <div className="mb-[16px]">
                  <div className="font-bold mb-[4px]">法律專長</div>
                  <ul className="list-disc list-outside pl-[20px]">
                    {professional.map((pro, i) => (
                      <li key={`${i}_${pro.text}`}>{pro.text}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {Object.keys(license).length ? (
                <div className="mb-[16px]">
                  <div className="font-bold mb-[4px]">證照進修</div>
                  <ul className="list-disc list-outside pl-[20px]">
                    {license.map((li, i) => (
                      <li key={`${i}_${li.text}`}>{li.text}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {Object.keys(works).length ? (
                <div className="mb-[16px]">
                  <div className="font-bold mb-[4px]">著作</div>
                  <ul className="list-disc list-outside pl-[20px]">
                    {works.map((work, i) => (
                      <li key={`show_${i}_${work.text}`}>{work.text}</li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <div
                className="absolute bottom-0 w-[300px] h-[74px] flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.56) 0%, #FFF 25.65%)",
                }}
              >
                <div
                  className="flex items-center justify-center text-[#c7a572]
          hover:bg-[#c7a572] hover:text-white cursor-pointer
          border-[#c7a572] border-[1px] w-[279px] h-[40px] rounded-[4px]"
                  onClick={() => {
                    setShow({
                      ...show,
                      [i]: true,
                    });
                  }}
                >
                  <div className="text-[14px] font-bold ">看更多</div>
                </div>
              </div>
            </div>
            {show[i] ? (
              <div
                className="
                            z-50
                        fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex
                        items-center justify-center
                          "
              >
                <ClickOutside
                  onClick={() => {
                    setShow(false);
                  }}
                  className="
                    relative w-[327px] md:w-[700px] h-[735px]
          bg-white rounded-[4px] pt-[64px] px-[40px]
          overflow-y-auto
          overflow-x-hidden
                  "
                >
                  <div>
                    <div className="xs:hidden md:absolute md:top-[20px] md:left-[-30px] md:border-[1px] md:w-[165px] md:h-[230px] md:border-[#c7a572]" />
                    <div className="xs:hidden md:absolute md:left-[-30px] md:top-[80%] md:border-[1px] md:w-[165px] md:h-[70px] md:border-[#c7a572]" />
                    <div className="xs:hidden md:absolute md:right-[-120px] md:top-[80%] md:border-[1px] md:w-[165px] md:h-[230px] md:border-[#c7a572]" />
                    <div className="absolute top-[24px] right-[24px]">
                      <button onClick={() => setShow(false)}>
                        <img
                          src="/close.svg"
                          alt=""
                          className="h-[40px] w-[40-px]"
                        />
                      </button>
                    </div>
                    <div className="flex">
                      <div className="relative z-50">
                        <img src={image.url} alt="" className="rounded-[4px]" />
                      </div>
                      <div>
                        <div className="w-[335px] md:ml-[20px] text-[16px] text-[#828282]">
                          <div className="text-[#001850] text-[20px] font-bold mb-[4px]">
                            {name[0].text}
                          </div>
                          <div className="bg-[#c7a572] h-[2px] w-[61px] mb-[16px]" />
                          <div className="mb-[16px]">
                            <div className="font-bold mb-[4px]">學歷</div>
                            {Object.values(education).length ? (
                              <ul className="list-disc pl-[20px] ">
                                {education.map((edu, i) => (
                                  <li key={`${i}_${edu.text}`}>{edu.text}</li>
                                ))}
                              </ul>
                            ) : null}
                          </div>
                          {Object.values(experiences).length ? (
                            <div className="mb-[16px]">
                              <div className="font-bold mb-[4px]">經歷</div>
                              <ul className="list-disc list-outside pl-[20px]">
                                {experiences.map((exp, i) => (
                                  <li key={`big_${i}_${exp.text}`} className="">
                                    {exp.text}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                          {Object.keys(license).length ? (
                            <div className="mb-[16px]">
                              <div className="font-bold mb-[4px]">證照進修</div>
                              <ul className="list-disc list-outside pl-[20px]">
                                {license.map((li, i) => (
                                  <li key={`${i}_${li.text}`}>{li.text}</li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                          {Object.keys(works).length ? (
                            <div className="mb-[16px]">
                              <div className="font-bold mb-[4px]">著作</div>
                              <ul className="list-disc list-outside pl-[20px]">
                                {works.map((work, i) => (
                                  <li key={`show_${i}_${work.text}`}>
                                    {work.text}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                          {Object.keys(professional).length ? (
                            <div className="mb-[16px]">
                              <div className="font-bold mb-[4px]">法律專長</div>
                              <ul className="list-disc list-outside pl-[20px]">
                                {professional.map((pro, i) => (
                                  <li key={`${i}_${pro.text}`}>{pro.text}</li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </ClickOutside>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default memo(Member);
