import Pro from "./Pro";
import React from "react";

const Props = ({ ps }) => {
  const renderImg = (type) => {
    if (type === "business") {
      return <img src="/business.svg" />;
    } else if (type === "civil") {
      return <img src="/civil.svg" />;
    } else if (type === "family") {
      return <img src="/family.svg" />;
    } else if (type === "labor") {
      return <img src="/labor.svg" />;
    } else if (type === "intellectual") {
      return <img src={"/intellectual.svg"} />;
    } else if (type === "criminal") {
      return <img src={"/chin.svg"} />;
    } else if (type === "public") {
      return <img src={"/public.svg"} />;
    }
  };
  return (
    <div
      className="
    w-full h-full flex flex-wrap px-[72px] pb-[100px] mt-[80px] justify-center"
    >
      {ps.map((p, i) => {
        return (
          <div
            key={`${i}_${p.icon}_${p.title}`}
            className="mb-[39px] mr-[43px]"
          >
            <Pro title={p.title} props={p.contents}>
              {renderImg(p.icon)}
            </Pro>
          </div>
        );
      })}
    </div>
  );
};

export default Props;
