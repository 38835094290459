import React, { memo, useEffect, useState } from "react";
import ClickOutside from "./ClickOutSide";

const MobileMember = ({ members }) => {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);

  if (index) {
    members = members.map((member, i) => {
      return {
        idx: i,
        ...member,
      };
    });
  }

  const clickLeft = () => {
    if (members?.length) {
      if (index == 0) {
        setIndex(members.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const clickRight = () => {
    if (members?.length) {
      if (index == members.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
  };

  const hellomember = (show, setShow) => {
    const current = members[index];
    if (!current) return <div>目前沒有資料</div>;
    const {
      education,
      experiences,
      image,
      license,
      name,
      professional,
      works,
    } = current.data;
    return (
      <div
        className="relative h-[745px] flex flex-wrap p-[16px] justify-center
      items-center w-[327px]  border-[1px] border-[#001850] rounded-[4px]"
      >
        {/*照片*/}
        <div className="w-[280px] h-[375px]">
          <img
            src={image.url}
            alt=""
            width={280}
            height={375}
            className="rounded-[4px]"
          />
        </div>
        <div className=" w-[280px] text-[16px] text-[#828282] mt-[16px] h-[320px] overflow-hidden">
          <div className="text-[#001850] text-[20px] font-bold mb-[4px]">
            {name[0].text}
          </div>
          <div className="bg-[#c7a572] h-[2px] w-[61px] mb-[16px]" />
          <div className="mb-[16px]">
            <div className="font-bold mb-[4px]">學歷</div>
            {Object.values(education).length ? (
              <ul className="list-disc pl-[20px] ">
                {education.map((edu, i) => (
                  <li key={`${i}_${edu.text}`}>{edu.text}</li>
                ))}
              </ul>
            ) : null}
          </div>
          {Object.values(experiences).length ? (
            <div className="mb-[16px]">
              <div className="font-bold mb-[4px]">經歷</div>
              <ul className="list-disc list-outside pl-[20px]">
                {experiences.map((exp, i) => (
                  <li key={`big_${i}_${exp.text}`} className="">
                    {exp.text}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {Object.keys(professional).length ? (
            <div className="mb-[16px]">
              <div className="font-bold mb-[4px]">法律專長</div>
              <ul className="list-disc list-outside pl-[20px]">
                {professional.map((pro, i) => (
                  <li key={`${i}_${pro.text}`}>{pro.text}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {Object.keys(license).length ? (
            <div className="mb-[16px]">
              <div className="font-bold mb-[4px]">證照進修</div>
              <ul className="list-disc list-outside pl-[20px]">
                {license.map((li, i) => (
                  <li key={`${i}_${li.text}`}>{li.text}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {Object.keys(works).length ? (
            <div className="mb-[16px]">
              <div className="font-bold mb-[4px]">著作</div>
              <ul className="list-disc list-outside pl-[20px]">
                {works.map((work, i) => (
                  <li key={`show_${i}_${work.text}`}>{work.text}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div
          className="absolute bottom-0 w-full h-[74px] flex items-center justify-center"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.56) 0%, #FFF 25.65%)",
          }}
          onClick={() => {
            setShow(true);
          }}
        >
          <div className="flex items-center justify-center border-1 border-[#c7a572] border-[1px] w-[279px] h-[40px] rounded-[4px]">
            <div className="text-[14px] font-bold text-[#c7a572]">看更多</div>
          </div>
        </div>
        {show ? (
          <div
            className="
          z-50
      fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex
      items-center justify-center
        "
          >
            <ClickOutside
              onClick={() => {
                setShow(false);
              }}
              className="
                relative w-[327px] h-[650px]
          bg-white rounded-[4px] py-[24px] px-[30px]
          mt-[50px]
          overflow-y-scroll
          border-[1px]
          border-[#001850]
          rounded-[4px]
              "
            >
              <div>
                <div
                  onClick={clickLeft}
                  style={{
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  }}
                  className="absolute left-[15px] top-[55%] bg-[#c7a572]
        w-[24px] h-[24px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        "
                >
                  <img src="/larrow.svg" className="w-[16x] h-[16px]" />
                </div>
                <div
                  onClick={clickRight}
                  style={{
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  }}
                  className="absolute right-[15px] top-[55%] bg-[#c7a572]
        w-[24px] h-[24px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        "
                >
                  <img src="/rarrow.svg" className="w-[16px] h-[16px]" />
                </div>
                <div className="xs:hidden md:absolute md:top-[20px] md:left-[-30px] md:border-[1px] md:w-[165px] md:h-[230px] md:border-[#c7a572]" />
                <div className="xs:hidden md:absolute md:left-[-30px] md:top-[80%] md:border-[1px] md:w-[165px] md:h-[70px] md:border-[#c7a572]" />
                <div className="xs:hidden md:absolute md:right-[-120px] md:top-[80%] md:border-[1px] md:w-[165px] md:h-[230px] md:border-[#c7a572]" />
                <div className="absolute top-[15px] right-[15px] z-20">
                  <button onClick={() => setShow(false)}>
                    <img
                      src="/close.svg"
                      alt=""
                      className="h-[24px] w-[24px]"
                      style={{
                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                      }}
                    />
                  </button>
                </div>
                <div className="flex flex-col relative z-10">
                  <div className="relative z-50 mb-[16px]">
                    <img
                      src={image.url}
                      alt=""
                      className="relative rounded-[4px]"
                    />
                  </div>
                  <div className="">
                    <div className="w-full md:ml-[20px] text-[16px] text-[#828282]">
                      <div className="text-[#001850] text-[20px] font-bold mb-[4px]">
                        {name[0].text}
                      </div>
                      <div className="bg-[#c7a572] h-[2px] w-[61px] mb-[16px]" />
                      <div className="mb-[16px]">
                        <div className="font-bold mb-[4px]">學歷</div>
                        {Object.values(education).length ? (
                          <ul className="list-disc pl-[20px] ">
                            {education.map((edu, i) => (
                              <li key={`${i}_${edu.text}`}>{edu.text}</li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                      {Object.values(experiences).length ? (
                        <div className="mb-[16px]">
                          <div className="font-bold mb-[4px]">經歷</div>
                          <ul className="list-disc list-outside pl-[20px]">
                            {experiences.map((exp, i) => (
                              <li key={`big_${i}_${exp.text}`} className="">
                                {exp.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      {Object.keys(license).length ? (
                        <div className="mb-[16px]">
                          <div className="font-bold mb-[4px]">證照進修</div>
                          <ul className="list-disc list-outside pl-[20px]">
                            {license.map((li, i) => (
                              <li key={`${i}_${li.text}`}>{li.text}</li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      {Object.keys(works).length ? (
                        <div className="mb-[16px]">
                          <div className="font-bold mb-[4px]">著作</div>
                          <ul className="list-disc list-outside pl-[20px]">
                            {works.map((work, i) => (
                              <li key={`show_${i}_${work.text}`}>
                                {work.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      {Object.keys(professional).length ? (
                        <div className="mb-[16px]">
                          <div className="font-bold mb-[4px]">法律專長</div>
                          <ul className="list-disc list-outside pl-[20px]">
                            {professional.map((pro, i) => (
                              <li key={`${i}_${pro.text}`}>{pro.text}</li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </ClickOutside>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="relative">
      <div
        onClick={clickLeft}
        className="absolute left-0 top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        "
      >
        <img src="/larrow.svg" />
      </div>
      <div
        onClick={clickRight}
        className="absolute right-0 top-[45%] bg-[#c7a572]
        w-[40px] h-[40px] rounded-full z-20
        flex justify-center items-center cursor-pointer
        "
      >
        <img src="/rarrow.svg" />
      </div>
      {hellomember(show, setShow)}
    </div>
  );
};
export default memo(MobileMember);
