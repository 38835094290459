const ProForMobile = ({ title, children, props }) => {
  return (
    <div className="relative">
      <div
        className={`absolute h-[473px] w-[333px] top-[-20px] border-[6px] border-[#c7a572]`}
      />
      <div className="bg-white w-[327px] py-[24px] px-[40px] h-[453px]">
        <div className="flex mb-[16px]">
          <div className="mr-[4px] mt-[4px]">
            {children}
            {/*<img src={iconUrl} alt="icon" />*/}
          </div>
          <div className="text-[#001850] text-[20px] font-bold">{title}</div>
        </div>
        <div className="h-[2px] bg-[#c7a572] w-[165px] mb-[16px]" />
        <div className="pl-[32px] text-[#828282]">
          <ul className="list-disc">
            {props?.map((pro, index) => (
              <li key={index}>{pro}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ProForMobile;
