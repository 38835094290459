import { useEffect, useRef, useState } from "react";

const Pro = ({ title, props, children }) => {
  // const resizeRef = useRef();
  // const [h, setH] = useState("130px");
  // const resize = () => {
  //   const height = resizeRef.current.offsetHeight;
  //   setH(`${height + 20}px`);
  // };
  // useEffect(() => {
  //   resize();
  //   window.addEventListener("resize", resize);
  //   return () => window.removeEventListener("resize", resize);
  // }, [title]);
  return (
    <div className="relative mt-[15px]">
      <div
        // style={{ height: h }}
        className={`absolute w-[403px] top-[-20px] border-[6px] border-[#c7a572] ml-[43px] h-[440px]`}
      />
      <div
        // ref={resizeRef}
        className="bg-white w-[383px] py-[24px] px-[40px] h-[417px] ml-[43px]"
      >
        <div className="flex mb-[16px]">
          <div className="mr-[4px] mt-[4px]">{children}</div>
          <div className="text-[#001850] text-[20px] font-bold">{title}</div>
        </div>
        <div className="h-[2px] bg-[#c7a572] w-[165px] mb-[16px]" />
        <div className="pl-[32px] text-[#828282]">
          <ul className="list-disc">
            {props?.map((pro, index) => (
              <li key={index}>{pro}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Pro;
