import classNames from "classnames";
import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Member from "./components/Member";
import Partner from "./components/Partner";
import useScreenType from "react-screentype-hook";
import MobileMember from "./components/MobileMember";
import Team from "./components/Title/Team";
import MobilePartner from "./components/MobilePartner";
import MobileProps from "./components/MobileProps";
import Props from "./components/Props";
import Modal from "./components/Modal";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const proScrollRef = useRef(null);
  const aboutRef = useRef(null);
  const memberRef = useRef(null);
  const contactRef = useRef(null);
  const [team_members] = useAllPrismicDocumentsByType("team_member");
  const [partners] = useAllPrismicDocumentsByType("partner");
  const screenType = useScreenType();

  const [showPP, setShowPP] = useState(false);
  const [cookie, setCookie] = useState(false);

  const [isHidden, setHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [cn, setCName] = useState("");
  const [cnEmail, setCnEmail] = useState("");
  const [cnPhone, setCnPhone] = useState("");
  const [cnContent, setCnContent] = useState("");
  const [type, setType] = useState("公法事件");

  const cookies = {
    title: "Cookie 聲明",
    content:
      "如果您造訪紳策法律事務所網站，絕大部分的Cookies（即儲存於您使用之產品上用於紀錄資訊的小型文本檔案）紀錄都僅儲存在您的產品上不會回傳，只有在少部分的情況下，Cookies紀錄可能會回傳至紳策法律事務所網站。您可透過瀏覽器，依自己的偏好管理您對於Cookies的設定。您可自由地將全部或部分（如第三方的Cookies）的Cookies設定為拒絕使用、刪除您瀏覽器內的Cookies紀錄或進行其他相關設定。如您選擇將Cookies設定為拒絕使用，則我們有可能無法提供您完整的服務。",
  };

  const privacyPolicy = {
    title: "紳策法律事務所隱私權政策聲明",
    content:
      "非常歡迎您瀏覽紳策法律事務所，為了讓您能夠安心瀏覽紳策法律事務所網站的各項服務與資訊，特此向您說明隱私權保護政策，以保障您的權益，請您詳閱下列內容：\n" +
      "一、隱私權保護政策的適用範圍隱私權保護政策內容，包括紳策法律事務所如何處理在您使用服務時收集到的個人識別資料。隱私權保護政策不適用於紳策法律事務所以外的相關連結外部網頁，也不適用於非紳策法律事務所所委託或參與管理的人員" +
      "\n" +
      "二、個人資料的蒐集、處理及利用方式" +
      "當您使用紳策法律事務所服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，紳策法律事務所不會將個人資料用於其他用途。" +
      "\n" +
      "三、資料之保護" +
      "紳策法律事務所連接主機均設有防火牆等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。" +
      "如因業務需要有必要委託其他單位提供服務時，紳策法律事務所亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。" +
      "\n" +
      "四、網站對外的相關連結" +
      "紳策法律事務所的網頁提供其他網站的網路連結，您也可經由紳策法律事務所所提供的連結，點選進入其他網站。但該連結網站不適用紳策法律事務所的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。" +
      "\n" +
      "五、與第三人共用個人資料之政策" +
      "紳策法律事務所絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。" +
      "前項但書之情形包括不限於：" +
      "經由您書面同意。" +
      "法律明文規定。" +
      "為免除您生命、身體、自由或財產上之危險。" +
      "與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。" +
      "當您在使用紳策法律事務所網站裡的行為，違反服務條款或可能損害或妨礙紳策法律事務所與其他使用者權益或導致任何人遭受損害時，經紳策法律事務所管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。" +
      "有利於您的權益。" +
      "\n" +
      "六、隱私權保護政策之修正" +
      "紳策法律事務所隱私權保護政策將因應需求隨時進行修正。",
  };

  const ps = [
    {
      title: "一般業務",
      contents: [
        "法律諮詢、法律意見提供",
        "常年法律顧問",
        "企業法令諮詢",
        "各類契約之擬定、修改及見證",
        "律師函、存證信函",
        "教育訓練與課程",
        "非訟事件處理",
        "媒體危機處理",
        "危老重建推動",
      ],
      icon: "business",
    },
    {
      title: "民事事件",
      contents: [
        "履行契約",
        "分配表異議之訴",
        "確認債權不存在",
        "無權佔用土地之返還、拆除、不當得利請求",
        "房屋買賣瑕疵爭議",
        "共有不動產現物單獨及合併分割",
        "國有不動產之占有、承租、承購",
        "工程瑕疵、工程款給付",
        "強制執行",
        "本票裁定",
        "支付命令",
      ],
      icon: "civil",
    },
    {
      title: "刑事案件",
      contents: [
        "貪污案件辯護",
        "金融犯罪案件辯護、告訴代理",
        "商業會計法案件辯護、告訴代理",
        "營業秘密法案件辯護、告訴代理",
        "詐欺、毒品、組織犯罪、槍砲之辯護",
        "財產犯罪之辯護、告訴代理",
        "未成年人犯罪之輔佐",
        "妨礙名譽犯罪之辯護、告訴代理",
        "車禍案件辯護、告訴代理",
      ],
      icon: "criminal",
    },
    {
      title: "公法事件",
      contents: [
        "申訴代理人",
        "訴願代理人",
        "行政訴訟代理人",
        "釋憲聲請",
        "稅務救濟",
        "環境保護事件",
      ],
      icon: "public",
    },
    {
      title: "家事事件",
      contents: [
        "夫妻財產制規劃",
        "預立遺囑、財產信託、遺囑執行人",
        "離婚、夫妻剩餘財產",
        "未成年子女親權、探視",
        "家庭暴力案件",
        "拋棄繼承、限定繼承、遺產糾紛",
        "收養案件",
      ],
      icon: "family",
    },
    {
      title: "勞資爭議",
      contents: [
        "勞資契約、工作規則擬定",
        "勞資調解、仲裁、訴訟代理",
        "勞動檢查諮詢",
        "職業工會籌設",
        "工會談判、團體協約協商與不當勞動行為裁決",
        "企業併購之勞動法律遵循與規劃 ",
      ],
      icon: "labor",
    },
    {
      title: "智慧財產權保護",
      contents: ["商標與專利註冊", "智慧財產權訴訟"],
      icon: "intellectual",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      let st = document.body.scrollTop || window.pageYOffset;
      setHidden(st > lastScrollTop);
      setLastScrollTop(st <= 0 ? 0 : st);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    if (menu || showPP || cookie) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menu, cookie, showPP]);

  useEffect(() => {
    const videoEl = videoRef.current;

    const handleCanPlayThrough = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
    const handleLoadStart = () => {
      setIsLoading(true);
    };
    const handleProgress = (e) => {
      if (e.target.duration) {
        let buffer = e.target.buffered;
        let duration = e.target.duration;
        setProgress(
          Math.round((buffer.end(buffer.length - 1) / duration) * 100),
        );
      }
    };
    const handleLoadeddata = () => {
      // console.log("loadeddata");
      // hello
    };

    const handleLoadedmetaData = () => {
      // console.log("loadedmetadata");
    };

    if (videoEl) {
      videoEl.addEventListener("canplaythrough", handleCanPlayThrough);
      videoEl.addEventListener("loadstart", handleLoadStart);
      videoEl.addEventListener("progress", handleProgress);
      videoEl.addEventListener("loadedmetadata", handleLoadedmetaData);
      videoEl.addEventListener("loadeddata", handleLoadeddata);
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("canplaythrough", handleCanPlayThrough);
        videoEl.removeEventListener("loadstart", handleLoadStart);
        videoEl.removeEventListener("progress", handleProgress);
        videoEl.removeEventListener("loadedmetadata", handleLoadedmetaData);
        videoEl.removeEventListener("loadeddata", handleLoadeddata);
      }
    };
  }, []);

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  // console.log("team_member", team_members);

  const groupByOrder = (tM) => {
    if (!tM) return;
    let finalResult = {};
    let index = 0;
    for (let i = 0; i < tM.length; i += 2) {
      finalResult[index] = [tM[i], tM[i + 1]];
      index++;
    }
    return finalResult;
  };
  // console.log("isHidden", isHidden);
  // console.log("screentype", screenType.isMobile);

  return (
    <div className="h-screen w-screen">
      {/*一進來progress區塊*/}
      {isLoading && (
        <div className="flex flex-col h-screen w-full justify-center items-center z-40">
          <div className="text-primary text-[32px] font-bold">Loading</div>
          <div className="text-primary text-[48px] font-bold mt-[8px]">
            {progress}%
          </div>
          <div className="w-[345px] h-[22px] border-2 border-primary mt-[16px]">
            <div
              style={{ width: `${progress}%` }}
              className={`h-[12px] bg-primary`}
            />
          </div>
        </div>
      )}
      {/*主要的畫面*/}
      <div
        className={classNames("h-screen w-full bg-red", {
          hidden: isLoading,
        })}
      >
        {/*header*/}
        <div
          className={classNames(
            "w-full transition-top ease-in-out duration-300 h-[85px] w-screen bg-[#001850] opacity-60 fixed left-0 right-0 px-[24px] md:px-[72px] z-50",
            {
              "top-[-90px]": screenType.isMobile && isHidden,
            },
            {
              "top-0": screenType.isMobile && !isHidden,
            },
          )}
        >
          <div className="h-full w-full flex justify-between items-center">
            <div>
              <img
                src="/logo.svg"
                alt=""
                className={classNames(
                  "h-[34px] w-[150px] md:h-[53px] md:w-[230px]",
                  {
                    hidden: screenType.isMobile && isHidden,
                  },
                )}
              />
            </div>
            <div className="hidden md:flex items-center">
              <div
                className="text-white text-[16px] font-bold cursor-pointer"
                onClick={() => handleScroll(aboutRef)}
              >
                關於我們
              </div>
              <div className="bg-white w-[2px] h-[16px] mx-[40px]"></div>
              <div
                className="text-white text-[16px] font-bold cursor-pointer"
                onClick={() => handleScroll(proScrollRef)}
              >
                專業領域
              </div>
              <div className="bg-white w-[2px] h-[16px] mx-[40px]"></div>
              <div
                className="text-white text-[16px] font-bold cursor-pointer"
                onClick={() => handleScroll(memberRef)}
              >
                團隊成員
              </div>
              <div className="bg-white w-[2px] h-[16px] mx-[40px]"></div>
              <div
                className="text-white text-[16px] font-bold cursor-pointer"
                onClick={() => handleScroll(contactRef)}
              >
                聯絡我們
              </div>
            </div>
            <div className="md:hidden text-white" onClick={() => setMenu(true)}>
              <img
                src="/menu.svg"
                alt="menu"
                className={classNames("h-[14] w-[20px] cursor-pointer", {
                  hidden: screenType.isMobile && isHidden,
                })}
              />
            </div>
          </div>
        </div>
        {menu && (
          <div className="fixed z-50 top-0 bottom-0 left-0 right-0 bg-white w-screen">
            <div className="absolute top-[40px] right-[40px] z-50">
              <button onClick={() => setMenu(false)}>
                <img src="/close.svg" alt="" className="h-[40px] w-[40-px]" />
              </button>
            </div>
            <div className="w-full flex flex-col items-center mt-[135px]">
              <button
                onClick={() => {
                  setMenu(false);
                  handleScroll(aboutRef);
                }}
              >
                <div className="text-[#001850] text-[20px] font-bold">
                  關於我們
                </div>
              </button>
              <button
                onClick={() => {
                  setMenu(false);
                  handleScroll(proScrollRef);
                }}
                className="mt-[24px]"
              >
                <div className="text-[#001850] text-[20px] font-bold">
                  專業領域
                </div>
              </button>
              <button
                onClick={() => {
                  setMenu(false);
                  handleScroll(memberRef);
                }}
                className="mt-[24px]"
              >
                <div className="text-[#001850] text-[20px] font-bold">
                  團隊成員
                </div>
              </button>
              <button
                onClick={() => {
                  setMenu(false);
                  handleScroll(contactRef);
                }}
                className="mt-[24px]"
              >
                <div className="text-[#001850] text-[20px] font-bold">
                  聯絡我們
                </div>
              </button>
            </div>
          </div>
        )}
        <div className="h-full relative">
          <video
            id="video1"
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-screen bottom-0 absolute left-0 top-0 right-0 object-cover object-center "
          >
            <source src="/landing-video.mp4" type="video/mp4" />
          </video>
          <div className="absolute bottom-[8px] animate-bounce cursor-pointer z-50 w-full flex justify-center">
            <button onClick={() => handleScroll(aboutRef)}>
              <img
                src="/ads.svg"
                className="cursor-pointer"
                width={32}
                height={82}
              />
            </button>
          </div>
          {!isLoading ? (
            <div className="relative flex justify-center items-center h-screen">
              <div className="">
                <img src="/shaodw.svg" className="h-auto w-full" />
              </div>
              <div className="text-white absolute w-[324px] h-[136px] md:w-[700px] md:h-[220px]">
                {/*最外環*/}
                <div className="absolute w-[4px] h-[60px] md:h-[165px] bg-white bottom-[24px] left-0 md:bottom-0 md:left-0 transition delay-100 duration-[400ms] animate-fadeIn " />
                <div className="absolute w-[4px] h-[60px] md:h-[165px] bg-white top-[24px] right-0 md:top-0 md:right-0 transition delay-100 duration-[400ms] animate-fadeIn" />
                {/*中間*/}
                <div className="absolute w-[4px] h-[60px] md:h-[165px] top-[20px] left-[20px] bg-white md:top-0 md:left-[18px] transition delay-100 duration-[400ms] animate-fadeIn " />
                <div className="absolute w-[4px] h-[60px] md:h-[165px] bg-white right-[20px] bottom-[20px] md:bottom-0 md:right-[18px] transition delay-100 duration-[400ms] animate-fadeIn " />
                {/*內側*/}
                <div className="absolute w-[4px] h-[60px] md:h-[165px] bg-white bottom-[24px] left-[40px] md:bottom-[15px] md:left-[36px] animate-fadeIn delay-200 duration-[400ms]" />
                <div className="absolute w-[4px] h-[60px] md:h-[165px] bg-white right-[40px] top-[24px] md:top-[15px] md:right-[36px] animate-fadeIn delay-200 duration-[400ms]" />
                {/*主要文字*/}
                <div
                  className="absolute left-[50%] top-[50%] text-shadow-lg
              transform translate-x-[-50%] translate-y-[-60%] font-bold animate-fadeInUp delay-300 duration-[400ms] w-[196px] md:w-[360px]"
                >
                  <div className="text-[26px] md:text-[48px]">
                    紳策法律事務所
                  </div>
                  <div className="text-[14px] md:text-[20px] flex justify-center">
                    實現卓越，達成使命！
                  </div>
                </div>
                {/*次要文字*/}
              </div>
            </div>
          ) : null}
        </div>

        {/*關於我們開始*/}
        <div
          id="prof"
          ref={aboutRef}
          className="relative h-full md:min-h-[800px] flex justify-center items-center"
        >
          {/*背景開始*/}
          <img
            src="/p2_main.webp"
            className="absolute bottom-0 w-full h-full object-cover object-center"
          />
          <img
            src="/p2.svg"
            className="absolute bottom-0 w-full h-full object-cover"
          />
          <img
            src="/p2_2.svg"
            className="absolute bottom-0 w-full object-cover"
          />
          {/*背景結束*/}

          <div className="absolute w-full top-[54px] md:top-[130px] flex justify-center">
            <div className="flex text-[20px] md:text-[32px] text-[#001F66] font-bold items-center">
              <div className="mr-[24px] md:mr-[44px]">
                <img src="/threeline.svg" className="h-[40px] md:h-[64px]" />
              </div>
              <div className="mr-[24px] md:mr-[44px]">關於我們</div>
              <div>
                <img src="/threeline.svg" className="h-[40px] md:h-[64px]" />
              </div>
            </div>
          </div>
          <div className="absolute flex top-[125px] md:top-[240px]">
            <div className="mr-[24px] w-[24px] h-[20px] md:w-[40px] md:h-[40px]">
              <img src="/upqu.svg" />
            </div>
            <div className="text-[#001F66] font-bold text-[26px] w-[250px] md:w-full md:text-[48px] mr-[9px] md:mr-[24px]">
              {screenType.isDesktop ||
              screenType.isLargeDesktop ||
              screenType.isTablet ? (
                <>理解、謹慎、專業，為您捍衛權益</>
              ) : (
                <div className="">
                  <div className="flex justify-center">理解、謹慎、專業</div>
                  <div className="flex justify-center">為您捍衛權益</div>
                </div>
              )}
            </div>
            <div>
              <img
                src="/downqu.svg"
                className="w-[24px] h-[20px] md:w-[40px] md:h-[40px]"
              />
            </div>
          </div>
          <div
            className="absolute flex flex-col items-center leading-[200%] top-[240px] md:top-[360px]
          text-[16px] md:text-[32px] font-normal w-[295px] md:w-[832px]
          text-[#001850]
          "
          >
            {screenType.isDesktop ||
            screenType.isLargeDesktop ||
            screenType.isTablet ? (
              <>
                <div>我們不僅了解法律，更致力於捍衛客戶的最佳利益</div>
                <div>就每一件委託都以最謹慎的態度為客戶擬定訴訟策略。</div>
                <div>本所團隊成員同時具備扎實的法學素養及多元的</div>
                <div>辦案經驗以熱情與耐心探索法律</div>
                <div>實現卓越，達成使命！</div>
              </>
            ) : (
              <>
                <div>我們不僅了解法律</div>
                <div>更致力於捍衛客戶的最佳利益</div>
                <div>就每一件委託都以最謹慎的態度為</div>
                <div>客戶擬定訴訟策略</div>
                <div>本所團隊成員同時具備扎實的法學</div>
                <div>素養及多元的辦案經驗</div>
                <div>以熱情與耐心探索法律</div>
                <div>實現卓越，達成使命！</div>
              </>
            )}
          </div>
        </div>
        {/*關於我們結束*/}

        {/*專業領域開始*/}
        <div
          ref={proScrollRef}
          className=" md:h-fit flex flex-col items-center bg-[#001f66] pb-[40px] md:pt-[75px]"
        >
          <div className="flex text-[20px] md:text-[32px] text-white font-bold mt-[30px]">
            <div className="mr-[24px] md:mr-[44px]">
              <img src="/white-viersion.svg" className="h-[40px] md:h-[64px]" />
            </div>
            <div className="mr-[24px] md:mr-[44px]">專業領域</div>
            <div>
              <img src="/white-viersion.svg" className="h-[40px] md:h-[64px]" />
            </div>
          </div>
          {screenType.isDesktop || screenType.isLargeDesktop ? (
            <Props ps={ps} />
          ) : (
            <MobileProps ps={ps} />
          )}
        </div>
        {/*專業領域結束*/}

        {/*/!*團隊成員開始*!/*/}
        <div
          ref={memberRef}
          className="relative min-w-[300px] py-[48px] md:pt-[90px] md:pb-[48px] px-[24px] md:px-[72px] flex flex-col items-center justify-center"
        >
          <div className="xs:hidden md:absolute md:right-[-30px] md:border-[1px] md:w-[165px] md:h-[230px] md:border-[#c7a572]" />
          <div className="xs:hidden md:absolute md:left-[-100px] md:top-[60%] md:border-[1px] md:w-[165px] md:h-[230px] md:border-[#c7a572]" />
          <div className="w-full flex justify-center items-center flex-col mb-[18px] md:mb-[96px]">
            <div className="mb-[32px] flex justify-center items-center">
              <Team />
            </div>
            <div className="w-full text-[16px] md:text-[24px] text-[#001850] mb-[16px] flex justify-start mb-[16px] font-bold">
              律師陣容
            </div>
            <div className="h-[3px] w-full bg-[#001850]" />
          </div>
          <div className="flex w-full flex-wrap justify-center items-center">
            {screenType.isDesktop || screenType.isLargeDesktop
              ? team_members &&
                team_members.sort((a, b) => a.data.order - b.data.order) &&
                Object.values(groupByOrder(team_members)).map((members, i) => (
                  <Member key={`big-${i}`} members={members} />
                ))
              : team_members &&
                team_members.sort((a, b) => a.data.order - b.data.order) && (
                  <MobileMember members={team_members} />
                )}
          </div>
        </div>
        <div className=" pb-[24px] md:pb-[60px] px-[24px] md:px-[72px] flex flex-col items-center pt-[16px] md:pt-[40px] bg-[#ecf2ff]">
          <div className="w-full flex justify-center items-center flex-col mb-[18px] md:mb-[40px]">
            <div className="w-full text-[16px] md:text-[24px] text-[#001850] mb-[16px] flex justify-start mb-[16px] font-bold">
              夥伴成員
            </div>
            <div className="w-full flex justify-center">
              <div className="h-[3px] w-full bg-[#001850]" />
            </div>
          </div>
          <div className="w-full flex justify-center">
            {screenType.isDesktop || screenType.isLargeDesktop
              ? partners && <Partner partners={partners} />
              : partners && <MobilePartner partners={partners} />}
          </div>
        </div>
        {/*團隊成員結束*/}
        {/*諮詢我們開始*/}
        <div className=" flex bg-white ">
          {/*左圖*/}
          <div className="hidden md:flex md:flex-1 md:w-[65%] h-screen">
            <img src="/consult.webp" className="h-full w-full object-cover" />
          </div>
          {/*表單*/}
          <div className=" w-full md:w-[35%] flex flex-col items-center pb-[24px] px-[24px] md:px-[70px]">
            <div className="flex text-[20px] md:text-[32px] text-[#001850] font-bold mt-[30px] md:mt-[100px]">
              <div className="mr-[24px] md:mr-[44px]">
                <img src="/threeline.svg" className="h-[40px] md:h-[64px]" />
              </div>
              <div className="mr-[24px] md:mr-[44px]">諮詢我們</div>
              <div>
                <img src="/threeline.svg" className="h-[40px] md:h-[64px]" />
              </div>
            </div>

            <form
              method="get"
              className="text-[#001850] font-bold mt-[40px] w-full "
            >
              <div>
                <div>姓名</div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-[#E0E0E0] dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => setCName(e.target.value)}
                />
              </div>
              <div className="mt-[24px]">
                <div>電子信箱</div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-[#E0E0E0]   dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => setCnEmail(e.target.value)}
                />
              </div>
              <div className="mt-[24px]">
                <div>聯絡電話</div>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-[#E0E0E0]   dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  onChange={(e) => setCnPhone(e.target.value)}
                />
              </div>
              <div className="mt-[24px]">
                <div>諮詢事項</div>
                <select
                  id="underline_select"
                  className="block py-2.5 px-0 w-full text-sm text-[#4f4f4f] bg-transparent border-0 border-b-2 border-[#E0E0E0]  focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="公法事件" selected>
                    公法事件
                  </option>
                  <option value="刑事事件">刑事事件</option>
                  <option value="民事事件">民事事件</option>
                  <option value="家事事件">家事事件</option>
                  <option value="勞資事件">勞資事件</option>
                  <option value="智慧財產權事件">智慧財產權事件</option>
                  <option value="其他事件">其他事件</option>
                </select>
              </div>
              <div className="mt-[24px]">
                <div className="mb-[8px]">備註</div>
                <textarea
                  id="comment"
                  rows="4"
                  className="w-full px-0 text-sm text-gray-900 bg-white
                  border-0 border-b-2 border-[#E0E0E0]
                 "
                  placeholder="寫備註"
                  onChange={(e) => setCnContent(e.target.value)}
                ></textarea>
              </div>
              <div
                className="h-[48px] mt-[46px] w-full flex items-center mt-[16px] md:mt-[32px]"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.56) 0%, #FFF 25.65%)",
                }}
              >
                <a
                  href={`mailto:shentselaw@gmail.com?subject=客戶諮詢-類型[${type}]&body=客戶Email:${cnEmail}%0D%0A客戶電話=${cnPhone}%0D%0A諮詢事項=${type}%0D%0A客戶姓名=${cn}%0D%0A客戶諮詢內容=${cnContent}`}
                  target="_top"
                  className="block"
                >
                  <div
                    className="flex items-center justify-center text-[#c7a572]
          hover:bg-[#c7a572] hover:text-white cursor-pointer
          border-[#c7a572] border-[1px] w-[279px] h-[40px] rounded-[4px]"
                  >
                    <div className="text-[14px] font-bold">傳送</div>
                  </div>
                </a>
              </div>
            </form>
          </div>
        </div>
        {/*諮詢我們結束*/}
        {/*地圖開始*/}
        {/*<div ref={contactRef} className="relative h-[800px] w-full">*/}
        {/*  <MyMap />*/}
        {/*</div>*/}
        {/*地圖結束*/}
        <div
          ref={contactRef}
          className="relative flex flex-col bg-[#001850] text-white py-[20px] px-[24px] md:px-[72px]"
        >
          <div>
            <img src="/logo.svg" className="w-[230px] h-[52px] mb-[18px]" />
          </div>
          <div className="flex flex-wrap md:justify-between mb-[24px]">
            <div className="mb-[24px]">
              <div className="text-white text-[20px] md:text-[24px] font-bold mt-[12px]">
                聯絡我們
              </div>
              <div className="mt-[8px] md:mt-[6px]">
                歡迎和我們聯繫，紳策將會和您一同解決問題。
              </div>
              <div className="mt-[8px] md:mt-[12px]">
                <div className="flex">
                  <img src="/home_white.svg" />
                  <div className="font-bold ml-[8px]">地址</div>
                </div>
                <div className="mt-[6px]">
                  臺北市大同區南京西路 62 號 14 樓之 1
                </div>
                <div className="mt-[6px]">(捷運中山站 6 號出口)</div>
              </div>
              <div className="flex mt-[8px] md:mt-[12px] flex-wrap">
                <div>
                  <div className="flex">
                    <img src="/home_phone.svg" />
                    <div className="font-bold ml-[8px]">電話</div>
                  </div>
                  <div className="mt-[6px]">02-2550-2021</div>
                </div>
                <div className="ml-[24px]">
                  <div className="flex">
                    <img src="/tele_white.svg" width={24} height={24} />
                    <div className="font-bold ml-[8px]">傳真</div>
                  </div>
                  <div className="mt-[6px]">02-2550-2023</div>
                </div>
              </div>
              <div className="mt-[8px]">
                <div className="flex">
                  <img src="/email.svg" width={24} height={24} />
                  <div className="font-bold ml-[8px]">信箱</div>
                </div>
                <div className="mt-[6px]">shentselaw@gmail.com</div>
              </div>
            </div>
            <div>
              {screenType.isMobile ? (
                <div className="relative">
                  <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <img
                      src="/marker.svg"
                      alt="marker"
                      className="w-[32px] h-[45px]"
                    />
                    <div
                      className="absolute padding-[24px] top-[-60px] md:left-[-40px] left-[-55px]
                          w-[148px] h-[40px] bg-[#f66] text-white text-[16px] font-bold rounded
                          flex justify-center items-center cursor-pointer
                          "
                      onClick={() => {
                        window.open(
                          "https://www.google.com/maps/place/%E7%B4%B3%E7%AD%96%E6%B3%95%E5%BE%8B%E4%BA%8B%E5%8B%99%E6%89%80/@25.0603202,121.5041415,15z/data=!4m7!3m6!1s0x3442a912e0bb1949:0x865d17dca91091fe!8m2!3d25.0527615!4d121.5192829!15sCi7lj7DljJfluILlpKflkIzljYDljZfkuqzopb_ot682MuiZnzE05qiT5LmL5LiAkgEObGVnYWxfc2VydmljZXPgAQA!16s%2Fg%2F11t34m8ncg?entry=tts&shorturl=1",
                          "_blank",
                        );
                      }}
                    >
                      點擊開啟Map
                    </div>
                  </div>
                  <img src="/map_mobile%20_fuck.jpg" />
                </div>
              ) : (
                <div className="relative">
                  <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <img
                      src="/marker.svg"
                      alt="marker"
                      className="w-[64] h-[92px]"
                    />
                    <div
                      className="absolute padding-[24px] top-[-60px] left-[-40px]
                          w-[148px] h-[40px] bg-[#f66] text-white text-[16px] font-bold rounded
                          flex justify-center items-center cursor-pointer
                          "
                      onClick={() => {
                        window.open(
                          "https://www.google.com/maps/place/%E7%B4%B3%E7%AD%96%E6%B3%95%E5%BE%8B%E4%BA%8B%E5%8B%99%E6%89%80/@25.0603202,121.5041415,15z/data=!4m6!3m5!1s0x3442a912e0bb1949:0x865d17dca91091fe!8m2!3d25.0527615!4d121.5192829!16s%2Fg%2F11t34m8ncg?entry=ttu",
                          "_blank",
                        );
                      }}
                    >
                      點擊開啟Map
                    </div>
                  </div>
                  <img
                    src="/map_fuck.webp"
                    className="md:w-[640px] md:h-[280px]"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between flex-wrap">
            <div className="flex">
              <div
                className="mb-[8px] cursor-pointer mr-[24px]"
                onClick={() => setShowPP(true)}
              >
                Privacy Policy
              </div>
              <div className="cursor-pointer" onClick={() => setCookie(true)}>
                Cookie
              </div>
            </div>
            <div>Copyright © 2023 紳策法律事務所 All rights reserved.</div>
          </div>
        </div>
        {cookie ? (
          <Modal
            title={cookies.title}
            content={cookies.content}
            control={setCookie}
          />
        ) : null}
        {showPP ? (
          <Modal
            title={privacyPolicy.title}
            content={privacyPolicy.content}
            control={setShowPP}
          />
        ) : null}
      </div>
    </div>
  );
}

export default App;
