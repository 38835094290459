import React from "react";
import ClickOutside from "./ClickOutSide";

const Modal = ({ title, content, control }) => {
  return (
    <div
      className="
          z-50
      fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex
      items-center justify-center
        "
    >
      <ClickOutside
        onClick={() => control(false)}
        className="
          relative
          w-[90%] h-[80%] md:w-[75%]
          bg-white rounded-[4px] pt-[64px] px-[40px] py-[24px]
          overflow-y-auto
          overflow-x-hidden
          border-[1px]
          border-[#001850]
          rounded-[4px]
      "
      >
        <div>
          <div className="absolute top-[10px] right-[10px]">
            <button onClick={() => control(false)}>
              <img src="/close.svg" alt="" className="h-[24px] w-[24px]" />
            </button>
          </div>
          <div className="flex items-center flex-col w-full">
            <div className="text-[24px] text-[#001850] font-bold mb-[24px] md:mb-[40px]">
              {title}
            </div>
            <div className="text-[#4f4f4f]">
              {content.split("\n").map((item, i) => {
                return (
                  <>
                    <p key={i}>{item}</p>
                    <br />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </ClickOutside>
    </div>
  );
};

export default Modal;
